// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'

export default function AccountPagePassword(props) {

    // const [currentPass, setCurrentPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [errorFields, setErrorFields] = useState("");
    const [token, setToken] = useState(localStorage.getItem(constant.CONSTANTS.USER_TOKEN));


    const handleChangePass = (e) => {
        e.preventDefault();
        let isValid = handleChangeValidations();

        console.log(errorFields)
        console.log(isValid)

        // return false
        console.log("Change")

        if (isValid) {
            var formData = new FormData();
            formData.append('token', token);
            formData.append('password', newPass);
            formData.append('confirm_password', confirmNewPass);
            console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.CHANGE_PASSWORD, data: formData }).then(res => {
                console.log(res)
                props.history.push('/');
            });
        }
    }

    const handleChangeValidations = () => {

        // console.log(/\S+@\S+\.\S+/.test(email))

        var isValid = true;
        let errorFields = {}

        // if (currentPass.length < 1) {
        //     errorFields.currentPass = true
        //     isValid = false
        // }

        if (newPass.length < 1) {
            errorFields.newPass = true
            isValid = false
        }

        if (confirmNewPass.length < 1) {
            errorFields.confirmNewPass = true
            isValid = false
        }

        if (newPass !== confirmNewPass) {
            errorFields.confirmNewPassNotEqual = true;
            isValid = false;
        }

        setErrorFields(errorFields)

        return isValid;

    }


    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        {/* <div className="form-group">
                            <label htmlFor="password-current">Current Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password-current"
                                placeholder="Current Password"
                                value={currentPass}
                                onChange={e => setCurrentPass(e.target.value)}
                            />
                            {errorFields.currentPass ? <span className="error-field">Please enter your current password</span> : null}
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="password-new">New Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password-new"
                                placeholder="New Password"
                                value={newPass}
                                onChange={e => setNewPass(e.target.value)}
                            />
                            {errorFields.newPass ? <span className="error-field">Please enter new password</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password-confirm"
                                placeholder="Confirm Password"
                                value={confirmNewPass}
                                onChange={e => setConfirmNewPass(e.target.value)}
                            />
                            {errorFields.confirmNewPass ? <span className="error-field">Please re enter your new password</span> : null}
                            {errorFields.confirmNewPassNotEqual ? <span className="error-field">New password and confirm password must be same</span> : null}


                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary text-color-white" onClick={handleChangePass}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
