/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import constant from '../services/constant'
import { toast } from 'react-toastify';

const apiService = {

    postRequest: async (options = {}) => {

        // let response = await fetch(constant.API.BASE_URL + constant.API.GET_CATEGORY + "depth=" + options.depth);
        // let data = await response.json()
        // return data;

        // console.log(options.url)
        // console.log(options.data)
        for (var i = 0; i < options.url.length; i++) {
            options.url = options.url.replace("#", "%23");
        }
        const response = await fetch(options.url, {
            
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                // 'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
          //      'Access-Control-Allow-Origin': '*'
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: options.data // body data type must match "Content-Type" header
        });

        var resClone = response.clone()
        // console.log(resClone)
        if (resClone.status == 400) {
            resClone.json().then(res => {
                // alert(res.message);

                if (res.message == "No Records") {
                } else {
                    toast.error(res.message);
                }

                return res;
            })
        } else {
            resClone.json().then(res => {
                // console.log(res.message);
                if (res.message == "Found") {
                } else {
                    toast.success(res.message);
                }
                return res;
            })
        }

        return response.json();

    },

    postRequestCheck: async (options = {}) => {

        // let response = await fetch(constant.API.BASE_URL + constant.API.GET_CATEGORY + "depth=" + options.depth);
        // let data = await response.json()
        // return data;

        console.log(options.url)
        console.log(options.data)

        for (var i = 0; i < options.url.length; i++) {
            options.url = options.url.replace("#", "%23");
        }

        console.log(options.url)

        const response = await fetch(options.url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
               // 'Access-Control-Allow-Origin': '*'
            },
            body: options.data // body data type must match "Content-Type" header

        });

        var resClone = response.clone()

        if (resClone.status == 400) {
            resClone.json().then(res => {
                // alert(res.message);
                toast.error(res.message);
                return res;
            })
        } else {

            resClone.json().then(res => {
                // alert(res.message);
                toast.success("Order placed successfully");

            })
        }

        return response

    },



    getRequest: async (options = {}) => {

        // console.log(options.url)
        // console.log(options.data)


        for (var i = 0; i < options.url.length; i++) {
            options.url = options.url.replace("#", "%23");
        }

        const response = await fetch(options.url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
              //  'Access-Control-Allow-Origin': '*'
                // 'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        var resClone = response.clone()


        console.log(resClone)

        if (resClone.status == 400) {
            resClone.json().then(res => {
                // alert(res.message);
                if (res.message == "No Records" || "Invalid Customer Token") {
                } else {
                    toast.error(res.message);
                }
                return res;
            })
        } else {
            resClone.json().then(res => {
                // console.log(res.message);
                if (res.message == "Found") {
                } else {
                    toast.success(res.message);
                }
                return res;
            })
        }

        return response.json();

    },

};

export default apiService;
