// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
// import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Quickview16Svg, Wishlist16Svg, Wishlist16SvgRed } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem, wishlistRemoveItem } from '../../store/wishlist';

function ProductCard(props) {

    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
        wishlistRemoveItem
    } = props;

    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    // React.useEffect(() => {

    //     if (product.images.length == 0) {
    //         console.log(product)
    //         product.images.push('images/no-item.jpg')
    //     }

    //     console.log(product)

    // }, [product])


    let badges = [];
    let image;
    let price;
    let features;

    if (product.badges.includes('sale')) {
        badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    }
    if (product.badges.includes('hot')) {
        badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    }
    if (product.badges.includes('new')) {
        badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;


    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src={product.images[0]} alt="" />
                </Link>
            </div>
        );
    } else {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img className="product-image__img" src='images/no-item.jpg' alt="" />
                </Link>
            </div>
        );
    }


    if (product.compareAtPrice) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={parseInt(product.price)} /></span>
                {' '}
                {
                    product.orignal_price != null ?
                        <span className="product-card__old-price"><Currency value={parseInt(product.original_price)} /></span>
                        :
                        null
                }
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={parseInt(product.price)} />
                {' '}
                <span className="product-card__old-price">
                    {product.original_price != null ? <Currency value={parseInt(product.original_price)} /> : null}
                </span>
            </div>
        );
    }

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes.filter((x) => x.featured).map((attribute, index) => (
                    <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
                ))}
            </ul>

        );
    }

    const addToWishlist = () => {
        console.log("addRemoveToWishlist")

        if (!product.is_wish_list) {
            product.is_wish_list = true
            return wishlistAddItem(product)
        } else {
            product.is_wish_list = false
            return wishlistRemoveItem(product.id)
        }

    }

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(product.slug)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link to={url.product(product)}>{product.name}</Link>
                </div>
                {features}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Availability:

                    {product.quantity == "0" ?
                        <span className="text-error"> Out Of Stock</span>
                        :
                        <span className="text-success"> In Stock</span>
                    }

                </div>
                {price}
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
    wishlistRemoveItem
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
