// react
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';

import shopApi from '../../api/shop';

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;


    const [departments, setDepartment] = useState([]);

    useEffect(() => {
        let canceled = false;

        // const treeToList = (departments, depth = 0) => (
        //     departments.reduce(
        //         (result, department) => [
        //             ...result,
        //             { depth, ...department },
        //             ...treeToList(department.children || [], depth + 1),
        //         ],
        //         [],
        //     )
        // );

        shopApi.getCategories({ depth: 2 }).then((departments) => {

            if (canceled) {
                return;
            }

            var someArr = []
            someArr.push({
                type: 'link',
                label: 'Home',
                url: '/',
                isSelected: false
            })
            someArr.push({
                type: 'link',
                label: 'New Arrival',
                url: '/shop/catalog',
                isSelected: false

            })

            var products = {
                type: 'link',
                label: 'Shop By Category',
                url: '/',
                children: [],
                isSelected: false

            }


            for (let i = 0; i < departments.length; i++) {
                var obj = {
                    type: 'link',
                    label: departments[i].name,
                    url: '/shop/category/' + departments[i].slug,
                    children: [],
                    isSelected: false

                }
                for (let j = 0; j < departments[i].children.length; j++) {

                    var obj1 = {
                        type: 'link',
                        label: departments[i].children[j].name,
                        url: '/shop/catalog/' + departments[i].children[j].slug,
                        children: [],
                        isSelected: false

                    }
                    obj.children.push(obj1)
                }
                products.children.push(obj)

            }


            someArr.push(products)




            // someArr.push({
            //     type: 'link',
            //     label: 'FAQs',
            //     url: '/site/faq',
            //     isSelected: false

            // })

            someArr.push({
                type: 'link',
                label: 'Contact Us',
                url: '/site/contact-us',
                isSelected: false

            })

            setDepartment(someArr);
        });

        return () => {
            canceled = true;
        };
    }, [setDepartment]);



    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {

        console.log(item)

        for (let i = 0; i < departments.length; i++) {
            departments[i].isSelected = false
        }

        item.isSelected = true;


        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={departments} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
