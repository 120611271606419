// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import { Check9x7Svg } from '../../svg';

export default function AccountPageEditAddress(props) {
    const [token, setToken] = useState(localStorage.getItem(constant.CONSTANTS.USER_TOKEN));
    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [unit, setUnit] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [isDefaultDyn, setIsDefaultDyn] = useState(false);
    const [errorFields, setErrorFields] = useState("");
    const [isAnyDefaultAddress, SetIsAnyDefaultAddress] = useState(false);


    const addressId = props.match.params.addressId
    console.log(addressId)


    useEffect(() => {
        console.log('mounted');
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_CUSTOMER_ADDRESS + token }).then(res => {
            console.log(res)
            console.log(res.data)

            if (res.data == undefined) {
                SetIsAnyDefaultAddress(false)
            }
            if (res.data) {
                for (let i = 0; i < res.data.length; i++) {

                    if (res.data[i].IsDefault == "Yes") {
                        SetIsAnyDefaultAddress(true)
                    }

                    if (addressId != "-1") {
                        if (res.data[i].ID == addressId) {
                            setTitle(res.data[i].Title)
                            setAddress(res.data[i].Address)
                            setUnit(res.data[i].Unit)
                            setMobileNumber(res.data[i].MobileNumber)
                            setIsDefaultDyn(res.data[i].IsDefault == "Yes" ? true : false)
                            setIsDefault(res.data[i].IsDefault == "Yes" ? true : false)
                        }
                    }
                }
            }
        });
        return () => console.log('unmounting...');
    }, [])


    const handleAddEdit = (e) => {
        e.preventDefault();
        let isValid = handleValidtion();

        console.log(errorFields)
        console.log(isValid)

        // return false
        console.log("Login")
        console.log(isDefault);
        console.log(isAnyDefaultAddress);

        if (isValid) {
            var formData = new FormData();
            formData.append('token', token);
            formData.append('title', title);
            formData.append('address', address);
            formData.append('unit', unit);
            formData.append('mobile_number', mobileNumber);
            if (!isAnyDefaultAddress) {
                formData.append('is_default', "Yes");
            } else {
                formData.append('is_default', isDefault ? "Yes" : "No");
            }
            if (addressId != "-1") {
                formData.append('id', addressId);
            }
            console.log(formData)
            var sendUrl = addressId != "-1" ? constant.API.BASE_URL + constant.API.EDIT_CUSTOMER_ADDRESS : constant.API.BASE_URL + constant.API.ADD_CUSTOMER_ADDRESS
            apiServices.postRequest({ url: sendUrl, data: formData }).then(res => {
                console.log(res)
                props.history.goBack()

                // props.history.push('/account/addresses');
            });
        }
    }


    const handleValidtion = () => {


        var isValid = true;
        let errorFields = {}


        if (title.length < 1) {
            errorFields.title = true
            isValid = false;
        }
        if (address.length < 1) {
            errorFields.address = true
            isValid = false;
        }
        if (unit.length < 1) {
            errorFields.unit = true
            isValid = false;
        }
        if (mobileNumber.length < 11) {
            errorFields.mobileNumber = true
            isValid = false;
        }



        setErrorFields(errorFields)
        return isValid;

    }

    const handleCheckChange = (val) => {
        setIsDefault(!isDefault)
        setIsDefaultDyn(!isDefault)
        console.log(isDefault)
    }

    return (
        <div className="card">
            <Helmet>
                {
                    addressId == "-1" ?
                        <title>{`Add Address — ${theme.name}`}</title>
                        :
                        <title>{`Edit Address — ${theme.name}`}</title>
                }


            </Helmet>

            <div className="card-header">
                {
                    addressId == "-1" ?
                        <h5>Add Address</h5>
                        :
                        <h5>Edit Address</h5>
                }

            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">


                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Enter title"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                            {errorFields.title ? <span className="error-field">Please enter title</span> : null}

                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Enter address"
                                value={address}
                                onChange={e => setAddress(e.target.value)}
                            />
                            {errorFields.address ? <span className="error-field">Please enter address</span> : null}

                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Unit</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Enter unit"
                                value={unit}
                                onChange={e => setUnit(e.target.value)}
                            />
                            {errorFields.unit ? <span className="error-field">Please enter unit</span> : null}

                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Mobile Number</label>
                            <input
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="03XX XXXXXXX"
                                type="text"
                                maxLength={11}
                                value={mobileNumber}
                                onChange={e => setMobileNumber(e.target.value)}
                            />
                            {errorFields.mobileNumber ? <span className="error-field">Please enter correct mobile number</span> : null}

                        </div>

                        <div className="form-group">
                            <div className="form-check">
                                <span className="form-check-input input-check">
                                    <span className="input-check__body">
                                        <input className="input-check__input"
                                            type="checkbox"
                                            id="checkout-different-address"
                                            value={isDefault}
                                            checked={isDefaultDyn}
                                            onChange={e => handleCheckChange(e.target.value)}
                                        // value={this.state.isTermsCheck}
                                        // onChange={e => this.setState({ isTermsCheck: !this.state.isTermsCheck })}
                                        />
                                        <span className="input-check__box" />
                                        <Check9x7Svg className="input-check__icon" />
                                    </span>
                                </span>
                                <label className="form-check-label" htmlFor="checkout-different-address">
                                    set as default
                                </label>
                            </div>
                        </div>


                        {/* <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-first-name"
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-last-name"
                                    placeholder="Last Name"
                                />
                            </div>
                        </div> */}

                        {/* <div className="form-group">
                            <label htmlFor="checkout-company-name">
                                Company Name
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-company-name"
                                placeholder="Company Name"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-country">Country</label>
                            <select id="checkout-country" className="form-control form-control-select2">
                                <option>Select a country...</option>
                                <option>United States</option>
                                <option>Russia</option>
                                <option>Italy</option>
                                <option>France</option>
                                <option>Ukraine</option>
                                <option>Germany</option>
                                <option>Australia</option>
                            </select>
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-street-address">Street Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Street Address"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-address">
                                Apartment, suite, unit etc.
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-address"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-city">Town / City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-city"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-state">State / County</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-state"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-postcode"
                            />
                        </div> */}

                        {/* <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-email">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="checkout-email"
                                    placeholder="Email address"
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-phone">Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-phone"
                                    placeholder="Phone"
                                />
                            </div>
                        </div> */}

                        <div className="form-group mt-3 mb-0">
                            <button className="btn btn-primary text-color-white" type="button" onClick={handleAddEdit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
