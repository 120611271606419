// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import { number } from 'prop-types';

import { toast } from 'react-toastify';
import { wishlistFetchItems } from '../../store/wishlist';
import { connect } from 'react-redux';

import Loader from '../shared/Loader'


function AccountPageLogin(props) {

    // const [logEmail, setLogEmail] = useState("salman09@mailinator.com");
    // const [logPassword, setLogPassword] = useState("123456");

    const [logEmail, setLogEmail] = useState("");
    const [logPassword, setLogPassword] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    // const [address, setAddress] = useState("");
    const [errorFields, setErrorFields] = useState("");
    const [loadingCustom, SetLoadingCustom] = useState(false);

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    // const errorFieldsTemp = {
    //     firstName: false,
    //     lastName: false,
    //     password: false,
    //     confirmPassword: false,
    //     mobileNumber: false,
    //     address: false,
    //     email: false,
    // }

    const handleRegister = (e) => {
        e.preventDefault();
        SetLoadingCustom(true)

        let isValid = handleRegisterValidation();

        console.log(errorFields)
        console.log(isValid)

        // return false;

        if (isValid) {

            console.log("Register")
            var formData = new FormData();
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('email_address', email);
            formData.append('password', password);
            formData.append('confirm_password', confirmPassword);
            formData.append('mobile_number', mobileNumber);
            // formData.append('address', address);

            console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.REGISTER_USER, data: formData }).then(res => {
                console.log(res)
                SetLoadingCustom(false)
            });

        } else {
            SetLoadingCustom(false)
        }

    }

    const handleLogin = (e) => {
        e.preventDefault();
        SetLoadingCustom(true)


        let isValid = handleLoginValidation();

        // console.log(errorFields)
        // console.log(isValid)

        // return false
        // console.log("Login")

        if (isValid) {
            var formData = new FormData();
            formData.append('email_address', logEmail);
            formData.append('password', logPassword);
            // console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.LOGIN_USER, data: formData }).then(res => {
                console.log("login")
                console.log("login")
                console.log("login")
                console.log("login")
                console.log(res)
                if (res.token != undefined) {
                    localStorage.setItem(constant.CONSTANTS.USER_TOKEN, res.token)
                    localStorage.setItem(constant.CONSTANTS.USER_DATA, JSON.stringify(res.data))
                    localStorage.setItem(constant.CONSTANTS.USER_LOGGED_IN, "true");

                    apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_WISHLIST + res.token }).then(res => {
                        // console.log(res.data);
                        if (res.data != undefined) {
                            for (let i = 0; i < res.data.length; i++) {
                                props.wishlistFetchItems(res.data[i]);
                            }
                        }
                        setTimeout(() => {
                            SetLoadingCustom(false)
                            props.history.goBack()
                        }, 1500);

                    })
                } else {
                    SetLoadingCustom(false)
                }
            });
        } else {
            SetLoadingCustom(false)
        }
    }

    const handleRegisterValidation = () => {

        // console.log(/\S+@\S+\.\S+/.test(email))

        var isValid = true;
        let errorFields = {}

        if (firstName.length < 1) {
            errorFields.firstName = true
            isValid = false
        }

        if (lastName.length < 1) {
            errorFields.lastName = true
            isValid = false
        }

        if (password.length < 1) {
            errorFields.password = true
            isValid = false
        }

        if (confirmPassword.length < 1 || confirmPassword !== password) {
            errorFields.confirmPassword = true
            isValid = false
        }

        if (mobileNumber.length < 11) {
            errorFields.mobileNumber = true
            isValid = false
        }

        if (!(/\S+@\S+\.\S+/.test(email)) || email.length < 1) {
            errorFields.email = true
            isValid = false
        }

        setErrorFields(errorFields)

        return isValid;

    }


    const handleLoginValidation = () => {

        // console.log(/\S+@\S+\.\S+/.test(logEmail))

        var isValid = true;
        let errorFields = {}


        if (logPassword.length < 1) {
            errorFields.logPassword = true
            isValid = false;
        }

        if (!(/\S+@\S+\.\S+/.test(logEmail)) || logEmail.length < 1) {
            errorFields.logEmail = true;
            isValid = false;
        }

        setErrorFields(errorFields)
        return isValid;

    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Login</h3>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={logEmail}
                                                onChange={e => setLogEmail(e.target.value)}
                                            />
                                            {errorFields.logEmail ? <span className="error-field">Please enter valid email</span> : null}

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={logPassword}
                                                onChange={e => setLogPassword(e.target.value)}
                                            />
                                            {errorFields.logPassword ? <span className="error-field">Please enter password</span> : null}

                                            <small className="form-text text-muted">
                                                <Link to="forget-password">Forgotten Password</Link>
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            id="login-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="login-remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        <button type="submit" onClick={handleLogin} className="btn btn-primary mt-2 mt-md-3 mt-lg-4 text-color-white">
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register</h3>
                                    <form >
                                        <div className="form-group">
                                            <label htmlFor="register-firstname">First name</label>
                                            <input
                                                id="register-firstname"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                            />
                                            {errorFields.firstName ? <span className="error-field">Please enter first name</span> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-lastname">Last name</label>
                                            <input
                                                id="register-lastname"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter last name"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                            />
                                            {errorFields.lastName ? <span className="error-field">Please enter last name</span> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-email">Email address</label>
                                            <input
                                                id="register-email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            {errorFields.email ? <span className="error-field">Please enter valid email address</span> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">Password</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter password"
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            {errorFields.password ? <span className="error-field">Please enter password</span> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-confirm">Confirm password</label>
                                            <input
                                                id="register-confirm"
                                                type="password"
                                                className="form-control"
                                                placeholder="Enter confirm password"
                                                value={confirmPassword}
                                                onChange={e => setConfirmPassword(e.target.value)}
                                            />
                                            {errorFields.confirmPassword ? <span className="error-field">Please enter correct password</span> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-mobile">Mobile number</label>
                                            <input
                                                id="register-mobile"
                                                type="text"
                                                className="form-control"
                                                placeholder="03XX XXXXXXX"
                                                value={mobileNumber}
                                                maxLength={11}
                                                onChange={e => setMobileNumber(e.target.value)}
                                            />
                                            {errorFields.mobileNumber ? <p className="error-field">Please enter valid mobile number</p> : null}
                                        </div>
                                        <button type="submit" onClick={handleRegister} className="btn btn-primary mt-2 mt-md-3 mt-lg-4 text-color-white">
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(loadingCustom) ? <Loader /> : null}
            </div>
        </React.Fragment>
    );
}



const mapStateToProps = (state) => ({
    wishlist: state.wishlist
});


const mapDispatchToProps = {
    wishlistFetchItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);