// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import constant from '../../services/constant';

export default function IndicatorAccount(props) {


    const isUserLogged = localStorage.getItem(constant.CONSTANTS.USER_LOGGED_IN) == "true" ? true : false
    const userDetails = JSON.parse(localStorage.getItem(constant.CONSTANTS.USER_DATA))


    // console.log(isUserLogged)
    const logout = () => {
        localStorage.clear()
    }

    const dropdown = (
        <div className="account-menu account-menu-custom">

            {
                isUserLogged ?
                    <div className="auth-div">

                        <div className="account-menu__divider" />
                        <Link to="/account/dashboard" className="account-menu__user account-menu__user-custom">
                            <div className="account-menu__user">
                                {/* <div className="account-menu__user-avatar">
                                    <img src="images/avatars/avatar-3.jpg" alt="" />
                                </div> */}
                                <div className="account-menu__user-info">
                                    <div className="account-menu__user-name">{userDetails.FirstName} {userDetails.LastName}</div>
                                    <div className="account-menu__user-email">{userDetails.EmailAddress}</div>
                                </div>
                            </div>
                        </Link>
                        <div className="account-menu__divider" />
                        <ul className="account-menu__links">
                            <li><Link to="/account/profile">Edit Profile</Link></li>
                            <li><Link to="/account/orders">Order History</Link></li>
                            <li><Link to="/account/addresses">Addresses</Link></li>
                            <li><Link to="/account/password">Change Password</Link></li>

                        </ul>
                        <div className="account-menu__divider" />
                        <ul className="account-menu__links">
                            <li><Link to="/account/login" onClick={logout}>Logout</Link></li>
                        </ul>

                    </div>
                    :
                    <div className="no-auth-div">
                        <form className="account-menu__form">
                            <div className="account-menu__form-title">Log In to Your Account</div>
                            <div className="form-group account-menu__form-button">
                                {/* <button className="btn btn-primary btn-sm link-color-custom"> */}
                                <Link className="btn btn-primary close-indicator-click btn-sm link-color-custom text-color-white" to="/account/login" >Login</Link>
                                {/* </button> */}
                            </div>
                            <div className="account-menu__form-link">
                                <Link to="/account/login">Create An Account</Link>
                            </div>
                        </form>
                    </div>
            }

        </div>
    );

    return (
        <Indicator url="/account" dropdown={dropdown} icon={props.iconSend == undefined || props.iconSend == null? < Person20Svg />:props.iconSend} />
    );
}
