// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
// import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'

export default function AccountPageAddresses(props) {
    const [token, setToken] = useState(localStorage.getItem(constant.CONSTANTS.USER_TOKEN));
    const [dataAddresses, setDataAddresses] = useState([]);
    const [selectedId, setSelectedId] = useState("");

    useEffect(() => {
        console.log('mounted');
        if (token) {
            getData()
        }

        return () => console.log('unmounting...');
    }, [])

    function getData() {
        console.log("Get data")
        if (token) {
            apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_CUSTOMER_ADDRESS + token }).then(res => {
                console.log(res)
                setDataAddresses(res.data)
                for (let i = 0; i < res.data?.length; i++) {
                    if (res.data[i].IsDefault == "Yes") {
                        selectAddressFn(res.data[i])

                    }
                }
            });
        }
    }

    function selectAddressFn(address) {
        console.log("salman")
        console.log(address.ID)
        setSelectedId(address.ID)
        console.log(selectedId)
        console.log(props.isCheckout)
        if (props.isCheckout == true) {
            props.sendData(address.ID);
        }
    }

    // const deleteAddress = () => {
    //     console.log()
    // }
    function deleteAddress(address) {
        console.log("delete address");
        console.log(address);


        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.DELETE_CUSTOMER_ADDRESS + token + "&id=" + address.ID }).then(res => {
            console.log(res)
            getData()
            // setDataAddresses(res.data)
        });

    }

    const addresses = dataAddresses == undefined ? null : dataAddresses.map((address) => (
        <React.Fragment key={address.id}>
            {/* selected-success */}
            <div className={address.ID == selectedId && props.isCheckout ? "addresses-list__item card address-card selected-success" : "addresses-list__item card address-card"} onClick={() => selectAddressFn(address)}  >
                {address.IsDefault !== "No" && <div className="address-card__badge text-color-white">Default</div>}

                <div className="address-card__body">
                    <div className="address-card__name">{`${address.Title}`}</div>
                    <div className="address-card__row">
                        {address.Address}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Unit</div>
                        <div className="address-card__row-content">{address.Unit}</div>
                    </div>

                    <div className="address-card__row">
                        <div className="address-card__row-title">Mobile Number</div>
                        <div className="address-card__row-content">{address.MobileNumber}</div>
                    </div>

                    <div className="address-card__footer">
                        <Link to={"/account/addresses/" + address.ID}>Edit</Link>
                        &nbsp;&nbsp;
                        <Link onClick={() => deleteAddress(address)} >Remove</Link>
                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>

            <Link to="/account/addresses/-1" className="addresses-list__item addresses-list__item--new">
                <div className="addresses-list__plus" />
                <div className="btn btn-primary btn-sm text-color-white">Add Address</div>
            </Link>
            <div className="addresses-list__divider" />
            {addresses}
        </div>
    );
}
