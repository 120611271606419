// react
import React, { useState } from 'react';

// application

import apiServices from '../../api/apiservices';
import constant from '../../services/constant'


export default function FooterNewsletter() {


    const [subscribeEmail, setSubscribeEmail] = useState("");
    const [errorFields, setErrorFields] = useState("");
    const subscribeFn = (e) => {
        e.preventDefault();
        let isValid = handleValidation();

        console.log(errorFields)
        console.log(isValid)


        if (isValid) {

            console.log("Subscribe");
            var formData = new FormData();
            formData.append('email_address', subscribeEmail);
            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.SUBS_TO_NEWSLETTER, data: formData }).then(res => {
                console.log(res)
                setSubscribeEmail("")
            });

        }

    }

    const handleValidation = () => {

        var isValid = true;
        let errorFields = {}

        if (!(/\S+@\S+\.\S+/.test(subscribeEmail)) || subscribeEmail.length < 1) {
            errorFields.subscribeEmail = true
            isValid = false
        }

        setErrorFields(errorFields)

        return isValid;

    }

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
                To subscribe to our newsletter please enter your email below.
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                    value={subscribeEmail}
                    onChange={e => setSubscribeEmail(e.target.value)}
                />
                <button type="submit" onClick={subscribeFn} className="footer-newsletter__form-button btn btn-primary">Subscribe</button>
            </form>
            {errorFields.subscribeEmail && subscribeEmail.length != 0 ? <span className="error-field">Please enter valid email address</span> : null}

            {/* <div className="footer-newsletter__text footer-newsletter__text--social">
                Follow us on social networks
            </div> */}

        </div>
    );
}
