// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'

export default function AccountReset(props) {

    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    // const [token, setToken] = useState(props.match.params.token);

    const [errorFields, setErrorFields] = useState("");


    const token = props.match.params.token

    // const verifyToken =
    // console.log(verifyToken)//123
    useEffect(() => {
        console.log('mounted');

        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.RESET_PASSWORD_TOKEN + token }).then(res => {
            console.log(res)
        });

        return () => console.log('unmounting...');
    }, [])



    const handleReset = (e) => {
        e.preventDefault();
        let isValid = handleChangeValidations();

        console.log(errorFields)
        console.log(isValid)

        // return false
        console.log("Forget")

        if (isValid) {
            var formData = new FormData();
            formData.append('token', token);
            formData.append('password', newPass);
            formData.append('confirm_password', confirmPass);
            console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.RESET_PASSWORD, data: formData }).then(res => {
                console.log(res)
                props.history.push('/account/login');
            });
        }
    }

    const handleChangeValidations = () => {

        // console.log(/\S+@\S+\.\S+/.test(email))

        var isValid = true;
        let errorFields = {}


        if (newPass.length < 1) {
            errorFields.newPass = true;
            isValid = false;
        }

        if (confirmPass.length < 1) {
            errorFields.confirmPass = true;
            isValid = false;
        }

        if (newPass !== confirmPass) {
            errorFields.confirmPassNotEqual = true;
            isValid = false;
        }

        setErrorFields(errorFields)

        return isValid;

    }


    return (
        <div className="card">
            <Helmet>
                <title>{`Reset Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Reset Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-confirm">New Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="email-forget"
                                placeholder="New Password"
                                value={newPass}
                                onChange={e => setNewPass(e.target.value)}
                            />
                            {errorFields.newPass ? <span className="error-field">Please enter password</span> : null}
                        </div>

                        <div className="form-group">
                            <label htmlFor="password-confirm">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="email-forget"
                                placeholder="Confirm Password"
                                value={confirmPass}
                                onChange={e => setConfirmPass(e.target.value)}
                            />
                            {errorFields.confirmPass ? <span className="error-field">Please re enter password</span> : null}
                            {errorFields.confirmPassNotEqual ? <span className="error-field">New password and confirm password must be same</span> : null}


                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary text-color-white" onClick={handleReset}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
