// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import Currency from '../shared/Currency';
import moment from 'moment'

export default function AccountPageOrderDetails(props) {

    const orderId = props.match.params.orderId
    console.log(orderId)
    const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
    const [orderDetail, setOrderDetail] = useState([]);

    useEffect(() => {
        console.log('mounted');
        // if (addressId != "-1") {
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_ORDER_DETAIL + token + "&invoice_number=" + orderId }).then(res => {
            console.log(res.data)
            var arr = []
            arr.push(res.data)
            setOrderDetail(arr)

        });
        // }
        return () => console.log('unmounting...');
    }, [])

    const orderProducts = orderDetail[0] == undefined ? null : orderDetail[0].product_data.map((product) => (

        <tr>
            <td>{product.VariantTitle}  </td>
            <td>{product.Quantity}  </td>
            <td><Currency value={product.Price} /> </td>
        </tr>

    ))

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-primary text-color-white">Back to list</Link>
                    </div>
                    <h5 className="order-header__title">Order # {orderDetail[0]?.InvoiceNumber}</h5>
                    <div className="order-header__subtitle">
                        Was placed on
                        {' '}
                        <mark className="order-header__date">{ moment(orderDetail[0]?.CreatedDateTime).format("DD/MM/YYYY, hh:mm A")}</mark>
                        {' '}
                        and is currently
                        {' '}
                        <mark className="order-header__status">{orderDetail[0]?.OrderStatus}</mark>
                        .
                    </div>

                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">

                                {orderProducts}

                            </tbody>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                <tr>
                                    <th>Subtotal</th>
                                    <td></td>
                                    <td><Currency value={orderDetail[0]?.SubTotal} /> </td>
                                </tr>

                                <tr>
                                    <th>Discount</th>
                                    <td></td>
                                    {
                                        orderDetail[0]?.discount_data != undefined ?
                                            <td> <Currency value={orderDetail[0]?.discount_data?.Type == "Percentage" ? (((parseFloat(orderDetail[0]?.SubTotal) * parseFloat(orderDetail[0]?.discount_data?.Value)) / 100) - parseFloat(orderDetail[0]?.SubTotal)) + parseFloat(orderDetail[0]?.SubTotal) : parseFloat(orderDetail[0]?.discount_data?.Value)} /></td>
                                            :
                                            <td><Currency value="0.00" /></td>
                                    }
                                </tr>

                                <tr>
                                    <th>Delivery Charges</th>
                                    <td></td>
                                    {
                                        orderDetail[0]?.DeliveryChargesTotal != undefined ?
                                            <td> <Currency value={orderDetail[0]?.DeliveryChargesTotal} />
                                            </td>
                                            :
                                            <td><Currency value="0.00" /></td>

                                    }
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <td></td>
                                    <td><Currency value={orderDetail[0]?.GrandTotal} /> </td>

                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                            <div className="address-card__name">{orderDetail[0]?.address_data?.Title}</div>
                            <div className="address-card__row">

                                {orderDetail[0]?.address_data?.Address}

                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>

                                <div className="address-card__row-content">{orderDetail[0]?.address_data?.MobileNumber}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );
}
