// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import AppLink from '../shared/AppLink';
import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedDown9x6Svg } from '../../svg';
import shopApi from '../../api/shop';

// data stubs
import navLinks from '../../data/headerNavigation';

function NavLinks(props) {

    const [headerList, setHeaderList] = useState("");

    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                const itemPosition = containerWidth - (
                    itemOffsetLeft + item.getBoundingClientRect().width
                );

                const megamenuPosition = Math.round(
                    Math.min(itemPosition, containerWidth - megamenuWidth),
                );

                megamenu.style.left = '';
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(
                    Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                );

                megamenu.style.right = '';
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    // const headerListing = shopApi.getHeaders({})

    async function fetchData() {

        setHeaderList("")

        const res = await shopApi.getHeaders({})
            .then(async resp => {
                console.log("headers")
                console.log(resp)

                var some;
                const resSome = await shopApi.getCategories({ depth: 2 })
                    .then(resp2 => {
                        console.log("headers getCategories")
                        console.log(resp2)

                        for (let i = 0; i < resp.data.length; i++) {

                            if (resp.data[i].title == "Shop By Category") {

                                var column1 = []
                                for (let j = 0; j < resp2.length; j++) {

                                    var innerItem = []
                                    for (let k = 0; k < resp2[j].children.length; k++) {

                                        var some3 = {
                                            size: 2,
                                            links: [
                                                {
                                                    title: resp2[j].children[k].name,
                                                    url: '/shop/catalog/' + resp2[j].children[k].slug,
                                                }
                                            ]

                                        }

                                        innerItem.push(some3)

                                    }

                                    var some2 = {
                                        size: 2,
                                        links: [
                                            {
                                                title: resp2[j].name,
                                                url: '/shop/category/' + resp2[j].slug,
                                                links: innerItem
                                            }
                                        ]
                                    }
                                    column1.push(some2)
                                }

                                some = {
                                    child_menus: resp.data[i].child_menus,
                                    title: resp.data[i].title,
                                    url: resp.data[i].url,
                                    id: resp.data[i].id,
                                    submenu: {
                                        type: 'megamenu',
                                        menu: {
                                            size: 'nl',
                                            columns: column1
                                        }
                                    }

                                }


                                resp.data[i] = some

                            }
                        }
                    })
                console.log("salman")
                console.log(resp.data)
                setHeaderList(resp.data)
            });
    }

    useEffect(() => {
        console.log('mounted');
        fetchData();
        return () => console.log('unmounting...');
    }, [])

    const linksList = Array.isArray(headerList) == false ? [] : headerList.map((item, index) => {
        let arrow;
        let submenu;

        if (item.submenu) {
            arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }

        if (item.submenu && item.submenu.type === 'menu') {
            submenu = (
                <div className="nav-links__menu">
                    <Menu items={item.submenu.menu} />
                </div>
            );
        }

        if (item.submenu && item.submenu.type === 'megamenu') {
            submenu = (
                <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
                    <Megamenu menu={item.submenu.menu} />
                </div>
            );
        }

        const classes = classNames('nav-links__item', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
                <AppLink to={item.url} {...item.props}>
                    <span>
                        {item.title}
                        {arrow}
                    </span>
                </AppLink>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="nav-links__list">
            {linksList}
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
