// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'



function SitePageContactUs() {
    const user = JSON.parse(localStorage.getItem(constant.CONSTANTS.USER_DATA))
    console.log(user)
    const [name, setName] = useState(user ? user.FirstName + " " + user.LastName : "");
    const [email, setEmail] = useState(user ? user.EmailAddress : "");
    const [number, setNumber] = useState(user ? user.MobileNumber : "");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [selectedQuery, setSelectedQuery] = useState("");
    const [errorFields, setErrorFields] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState("");


    const [fileInputKey, setFileInputKey] = useState(Date.now());



    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];

    console.log(name)
    console.log(email)


    const contactSubmit = (e) => {
        e.preventDefault();

        console.log(selectedQuery)
        // return false


        let isValid = handleValidation();

        console.log(errorFields)
        console.log(isValid)

        if (isValid) {
            var formData = new FormData();
            formData.append('name', name);
            formData.append('email_address', email);
            formData.append('phone_number', number);
            formData.append('subject', subject);
            formData.append('type', selectedQuery);
            formData.append('invoice_number', invoiceNumber);
            if (isFilePicked) {
                formData.append('upload_file', selectedFile);
            }
            formData.append('message', message);
            formData.append('query', selectedQuery);

            console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.CONTACT_US, data: formData }).then(res => {

                setName("")
                setEmail("")
                setNumber("")
                setSubject("")
                setMessage("")
                setSelectedQuery("")
                setInvoiceNumber("")
                setSelectedFile(null)
                setFileInputKey(Date.now())
                console.log(res)
            });
        } else {
            console.log("not valid")
        }
    }

    const handleValidation = () => {

        console.log(message.length)

        var isValid = true;
        let errorFields = {}

        if (name.length < 1) {
            errorFields.name = true
            isValid = false
        }

        if (number.length < 11) {
            errorFields.number = true
            isValid = false
        }


        if (subject.length < 1) {
            errorFields.subject = true
            isValid = false
        }


        if (message.length < 1) {
            errorFields.message = true
            isValid = false
        }

        if ((selectedQuery == "Refund & Exchange" || selectedQuery == "Complain") && invoiceNumber.length < 1) {

            errorFields.invoiceNumber = true
            isValid = false
        }


        if (!(/\S+@\S+\.\S+/.test(email)) || email.length < 1) {
            errorFields.email = true
            isValid = false
        }

        setErrorFields(errorFields)

        return isValid;

    }


    const onChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const selectType = (e) => {
        console.log(e.target.value)
        e.preventDefault();
        setSelectedQuery(e.target.value)
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>


            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    {/* <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Our Address</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                {theme.contacts.address}
                                                <br />
                                                {theme.contacts.email}
                                                <br />
                                                {theme.contacts.phone}
                                            </p>

                                            <p>
                                                <strong>Opening Hours</strong>
                                                <br />
                                                {theme.contacts.timing}
                                            </p>

                                        </div>
                                    </div> */}

                                    <div className="col-12 col-lg-12">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>
                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Your Name*</label>
                                                    <input type="text"
                                                        id="form-name"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                                    {errorFields.name ? <span className="error-field">Please your name</span> : null}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email*</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    {errorFields.email ? <span className="error-field">Please enter valid email</span> : null}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Mobile Number*</label>
                                                <input
                                                    id="form-subject"
                                                    className="form-control"
                                                    placeholder="03XX XXXXXXX"
                                                    type="text"
                                                    maxLength={11}
                                                    value={number}
                                                    onChange={e => setNumber(e.target.value)}
                                                />
                                                {errorFields.number ? <span className="error-field">Please enter correct number</span> : null}
                                            </div>

                                            {/* <p><strong>Select</strong></p> */}

                                            <div className="form-group">
                                                <label htmlFor="select-default">Query</label>
                                                <select id="select-default" className="form-control"
                                                    value={selectedQuery}
                                                    onChange={selectType} >
                                                    <option value="null">Select value...</option>
                                                    <option value="Refund & Exchange">Refund & Exchange</option>
                                                    <option value="Complain">Complain</option>
                                                    <option value="Request A Product">Request A Product</option>
                                                </select>
                                            </div>
                                            {
                                                selectedQuery == "Refund & Exchange" || selectedQuery == "Complain" ?
                                                    <div className="form-group">
                                                        <label htmlFor="form-subject">Invoice Number*</label>
                                                        <input type="text"
                                                            id="form-subject"
                                                            className="form-control"
                                                            placeholder="Invoice Number"
                                                            value={invoiceNumber}
                                                            onChange={e => setInvoiceNumber(e.target.value)}
                                                        />
                                                        {errorFields.invoiceNumber ? <span className="error-field">Please enter invoice number</span> : null}
                                                    </div>
                                                    : null

                                            }




                                            <div className="form-group">
                                                <label htmlFor="form-subject">Upload File</label>
                                                <input type="file"
                                                    name="file"
                                                    id="form-subject"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    onChange={onChangeFile}
                                                    key={fileInputKey}
                                                // onChange={e => setSubject(e.target.value)}
                                                />
                                                {/* {errorFields.subject ? <span className="error-field">Please upload file</span> : null} */}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-subject">Subject*</label>
                                                <input type="text"
                                                    id="form-subject"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    value={subject}
                                                    onChange={e => setSubject(e.target.value)}
                                                />
                                                {errorFields.subject ? <span className="error-field">Please enter subject</span> : null}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-message">Message*</label>
                                                <textarea
                                                    id="form-message"
                                                    className="form-control"
                                                    rows="4"
                                                    value={message}
                                                    onChange={e => setMessage(e.target.value)}
                                                />
                                                {errorFields.message ? <span className="error-field">Please enter message</span> : null}
                                            </div>
                                            <button type="submit" className="btn btn-primary text-color-white" onClick={contactSubmit}>Send Message</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <BlockMap /> */}

        </React.Fragment>
    );
}

export default SitePageContactUs;
