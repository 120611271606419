// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import Pagination from '../shared/Pagination';

// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant';
import Currency from '../shared/Currency';
import moment from 'moment'
export default class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            page: 1,
            userToken: localStorage.getItem(constant.CONSTANTS.USER_TOKEN),
            seletedStatus: "Pending"
        };
        this.getOrdersList("Pending")
    }



    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };

    getOrdersList(status) {

        if (this.state.userToken) {
            var formData = new FormData();
            formData.append('token', this.state.userToken);
            formData.append('order_status', status);

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.GET_ORDER_HISTORY, data: formData }).then(res => {
                console.log(res)
                this.setState({ orders: res.data })
            });
        }
    }



    render() {
        const { page, orders, seletedStatus, userToken } = this.state;

        const cancelOrder = (order) => {
            console.log(order)

            apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.CANCEL_ORDER + userToken + "&id=" + order.ID }).then(res => {
                console.log(res.data)
                this.getOrdersList("Pending")
            });

        }

        const ordersList = orders == undefined ? null : orders.map((order) => (
            <tr key={order.id}>
                <td><Link to={"/account/orders/" + order.InvoiceNumber}>{`#${order.InvoiceNumber}`}</Link></td>
                <td>{moment(order.CreatedDateTime).format("DD/MM/YYYY, hh:mm A")}</td>
                <td>{order.OrderStatus}</td>
                <td><Currency value={order.GrandTotal} /> </td>
                {
                    order.OrderStatus == "Pending" ?
                        <td><p onClick={() => cancelOrder(order)} className="btn btn-xs btn-danger text-color-white cancel-btn-custom">Cancel</p></td>
                        :
                        null
                }
            </tr>
        ));

        const handleStatus = (status) => {
            console.log(status);
            this.setState({ seletedStatus: status })
            this.getOrdersList(status)
        }

        return (

            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Order History</h5>
                </div>
                <div className="card-divider" />

                <div className="order-status-nav">
                    <ul className="nav">
                        <li className="nav-item">
                            <div className={seletedStatus == "Pending" ? "text-color-primary nav-link active" : "text-color-primary nav-link"} onClick={() => handleStatus("Pending")}  >Pending</div>
                        </li>
                        <li className="nav-item">
                            <div className={seletedStatus == "Processed" ? "text-color-primary nav-link active" : "text-color-primary nav-link"} onClick={() => handleStatus("Processed")}>Processed</div>
                        </li>
                        <li className="nav-item">
                            <div className={seletedStatus == "Cancel" ? "text-color-primary nav-link active" : "text-color-primary nav-link"} onClick={() => handleStatus("Cancel")}>Cancelled</div>
                        </li>
                        <li className="nav-item">
                            <div className={seletedStatus == "Completed" ? "text-color-primary nav-link active" : "text-color-primary nav-link"} onClick={() => handleStatus("Completed")}>Completed</div>
                        </li>
                    </ul>
                </div>

                <div className="card-divider" />

                <div className="card-table">
                    <div className="table-responsive-sm order-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Order Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    {
                                        seletedStatus == "Pending" ?
                                            <th>Action</th>
                                            :
                                            null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {ordersList}
                            </tbody>
                        </table>

                        {ordersList == null ?
                            <p className="no-orders">
                                No {seletedStatus} Orders
                            </p>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="card-divider" />
            </div>
        );
    }
}
