import { cartDiscountReset } from './cartActions';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_RESET, CART_DISCOUNT_RESET, CART_UPDATE_DELIVERY_CHARGES } from './cartActionTypes';
import { toast } from 'react-toastify';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => extraLine.type == 'discount' ? total - extraLine.price : total + extraLine.price, 0);
}

function calcDiscountPrice(total, discount) {
    // updateQuantities()

    console.log("discount")
    console.log("discount")
    console.log("discount")
    console.log(discount)
    console.log(total)

    if (discount.Type == "Percentage") {
        return (total * parseInt(discount.Value)) / 100
    } else {
        return parseInt(discount.Value)
    }
}

function calcDiscountPriceAgain(total, discount, minAmount) {
    // updateQuantities()
    console.log("calcDiscountPriceAgain")
    console.log("calcDiscountPriceAgain")
    console.log("calcDiscountPriceAgain")
    console.log(total)
    console.log(discount)
    console.log(minAmount)
    console.log(parseInt(minAmount == undefined || minAmount == "" ? "0" : minAmount))

    minAmount = parseInt(minAmount == undefined || minAmount == "" || isNaN(minAmount) ? "0" : minAmount)
    console.log("total")
    console.log(total)
    console.log(minAmount)
    console.log(minAmount > total)
    if (minAmount > total) {
        return 0
    }

    console.log("Check has own property")
    console.log(discount.hasOwnProperty('discountValue'))
    if (discount.hasOwnProperty('discountValue')) {
        if (discount.discountType == "Percentage") {
            return (total * parseInt(discount.discountValue)) / 100
        } else {
            return parseInt(discount.discountValue)
        }
    } else {
        if (discount.discountType == "Percentage") {
            return (total * parseInt(discount.price)) / 100
        } else {
            return parseInt(discount.price)
        }
    }


}

function addItem(state, product, options, quantity) {

    console.log("state Add Item")
    console.log(state)
    console.log(product)
    console.log(options)
    console.log(quantity)

    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.price,
            total: product.price * quantity,
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];
        console.log(product.quantity)
        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);

    const newExtraLines = state.extraLines.map((item) => {

        if (item.type == 'discount') {
            item.price = calcDiscountPriceAgain(subtotal, item)
            if (item.price == 0) {
                cartStateDiscountReset(state);
            }
        }

        if (item.type == "delivery") {
            item.price = calDeliveryCharges(subtotal, item.deliveryValue, item.freeDeliveryAmount)
        }

        return {
            ...item,
            price: item.price,
        };
    });

    const total = calcTotal(subtotal, newExtraLines);
    console.log("newExtraLines addItem")
    console.log(newExtraLines)
    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);

    const newExtraLines = state.extraLines.map((item) => {

        if (item.type == 'discount') {
            item.price = calcDiscountPriceAgain(subtotal, item, state.minOrderDiscountAmount)
            cartStateDiscountReset(state);
        }

        if (item.type == "delivery") {
            item.price = calDeliveryCharges(subtotal, item.deliveryValue, item.freeDeliveryAmount)
        }
        return {
            ...item,
            price: item.price,
        };
    });
    console.log(newExtraLines)

    const total = calcTotal(subtotal, newExtraLines);

    let disCode = state.discountCode
    let isApply = state.isApplyDisabled
    let extraLines = state.extraLines

    if (newItems.length == 0 || state.minOrderDiscountAmount > subtotal) {
        disCode = ""
        isApply = false
        extraLines = [
            {
                type: 'discount',
                title: 'Discount',
                price: 0,
                discountType: "",
                discountValue: 0
            },
            {
                type: 'delivery',
                title: 'Delivery Charges',
                price: state.extraLines[1].deliveryValue,
                deliveryValue: state.extraLines[1].deliveryValue,
                freeDeliveryAmount: state.extraLines[1].freeDeliveryAmount
            },
        ]

    }

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
        discountCode: disCode,
        isApplyDisabled: isApply,
        extraLines: extraLines,
        // isApplyDisabled: state.minOrderDiscountAmount > subtotal

    };
}

function cartReset(state) {
    console.log(state)

    return {
        ...state,
        lastItemId: 0,
        quantity: 0,
        items: [],
        subtotal: 0,
        isApplyDisabled: false,
        discountCode: "",
        minOrderDiscountAmount: "",
        extraLines: [ // shipping, taxes, fees, .etc
            {
                type: 'discount',
                title: 'Discount',
                price: 0,
                discountType: "",
                discountValue: 0,

            },
            {

                type: 'delivery',
                title: 'Delivery Charges',
                price: 0,
                deliveryValue: 0,
                freeDeliveryAmount: 0
            },
        ],
        total: 0,
    };
}

function cartStateDiscountReset(state) {

    console.log("reset Discount")
    console.log(state)

    return {
        ...state,
        lastItemId: state.lastItemId,
        quantity: state.quantity,
        items: state.items,
        subtotal: state.subtotal,
        isApplyDisabled: false,
        discountCode: "",
        minOrderDiscountAmount: "",
        extraLines: [
            {
                type: 'discount',
                title: 'Discount',
                price: 0,
                discountType: "",
            },
            {
                type: 'delivery',
                title: 'Delivery Charges',
                price: state.extraLines[1].price,
                deliveryValue: state.extraLines[1].deliveryValue,
                freeDeliveryAmount: state.extraLines[1].freeDeliveryAmount
            },
        ],
        total: state.subtotal,
    };
}

function updateQuantities(state, data) {
    let needUpdate = false;

    console.log("salman")
    console.log(state)
    console.log(data)

    if (data.quantities != undefined) {
        const quantities = data.quantities
        const newItems = state.items.map((item) => {
            const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);
            if (!quantity) {
                return item;
            }
            needUpdate = true;

            return {
                ...item,
                quantity: quantity.value,
                total: quantity.value * item.price,
            };
        });

        console.log(newItems)

        for (let i = 0; i < newItems.length; i++) {
            if (newItems[i].quantity > newItems[i].product.quantity) {
                toast.error(newItems[i].product.name + " quantity exceeds available quatity");
                needUpdate = false
            }
        }

        if (needUpdate) {
            const subtotal = calcSubtotal(newItems);

            const newExtraLines = state.extraLines.map((item) => {

                if (item.type == 'discount') {
                    item.price = calcDiscountPriceAgain(subtotal, item, state.minOrderDiscountAmount)
                    cartStateDiscountReset(state);
                }

                if (item.type == "delivery") {
                    item.price = calDeliveryCharges(subtotal, item.deliveryValue, item.freeDeliveryAmount)
                }

                return {
                    ...item,
                    price: item.price,
                };
            });

            let disCode = state.discountCode
            let isApply = state.isApplyDisabled
            let extraLines = state.extraLines

            if (newItems.length == 0 || state.minOrderDiscountAmount > subtotal) {
                disCode = ""
                isApply = false
                extraLines = [
                    {
                        type: 'discount',
                        title: 'Discount',
                        price: 0,
                        discountType: "",
                        discountValue: 0
                    },
                    {
                        type: 'delivery',
                        title: 'Delivery Charges',
                        price: state.extraLines[1].deliveryValue,
                        deliveryValue: state.extraLines[1].deliveryValue,
                        freeDeliveryAmount: state.extraLines[1].freeDeliveryAmount
                    },
                ]

            }

            const total = calcTotal(subtotal, newExtraLines);
            console.log("newExtraLines update")
            console.log(newExtraLines)
            return {
                ...state,
                items: newItems,
                quantity: calcQuantity(newItems),
                subtotal,
                total,
                discountCode: disCode,
                isApplyDisabled: isApply
            };
        }
    } else {
        const discount = data.discount;
        console.log("discount salman")
        console.log(discount)
        const { items, extraLines, subtotal } = state;

        const newExtraLines = extraLines.map((item) => {

            if (item.type == 'discount') {
                item.price = calcDiscountPrice(subtotal, discount)
                item.discountType = discount.Type;
                item.discountValue = parseInt(discount.Value)
            }

            if (item.type == "delivery") {
                item.price = calDeliveryCharges(subtotal, item.deliveryValue, item.freeDeliveryAmount)
            }

            return {
                ...item,
                price: item.price,
                discountType: item.discountType,
            };
        });

        const total = calcTotal(subtotal, newExtraLines);
        return {
            ...state,
            items: items,
            quantity: calcQuantity(items),
            subtotal,
            total,
            isApplyDisabled: true,
            discountCode: discount.Code,
            minOrderDiscountAmount: discount.MinimumAmount
        };
    }

    return state;
}

function updateDeliveryCharges(state, data) {
    const delivery = parseFloat(data.delivery);
    const freeDeliveryAmount = parseFloat(data.freeDeliveryAmount);
    const { extraLines } = state;

    // console.log(freeDeliveryAmount !== 0 ? (subtotal > freeDeliveryAmount ? 0 : delivery) : delivery)


    const newExtraLines = extraLines.map((item) => {

        if (item.type == 'delivery') {
            item.price = delivery
            item.deliveryValue = delivery
            item.freeDeliveryAmount = freeDeliveryAmount
            return {
                ...item,
                price: item.price,
                freeDeliveryAmount: item.freeDeliveryAmount,
                deliveryValue: item.deliveryValue,
            };
        } else {
            return {
                ...item,
                price: item.price,
            };
        }

    });

    console.log("newExtraLines")
    console.log(newExtraLines)

    return {
        ...state,
        extraLines: newExtraLines
    };

}

function calDeliveryCharges(subtotal, deliveryChages, freeDeliveryAmount) {

    console.log(subtotal)
    console.log(deliveryChages)
    console.log(freeDeliveryAmount)

    return freeDeliveryAmount !== 0 ? (subtotal >= freeDeliveryAmount ? 0 : deliveryChages) : deliveryChages
}

const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    isApplyDisabled: false,
    discountCode: "",
    minOrderDiscountAmount: "",
    extraLines: [ // shipping, taxes, fees, .etc
        {
            type: 'discount',
            title: 'Discount',
            price: 0,
            discountType: "",
            discountValue: 0,
        },
        {
            type: 'delivery',
            title: 'Delivery Charges',
            price: 0,
            deliveryValue: 0,
            freeDeliveryAmount: 0
        },
    ],
    total: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(state, action.product, action.options, action.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.data);

        case CART_RESET:
            return cartReset(state, action.data);

        case CART_DISCOUNT_RESET:
            return cartStateDiscountReset(state, action.data);

        case CART_UPDATE_DELIVERY_CHARGES:
            return updateDeliveryCharges(state, action.data);


        default:
            return state;
    }
}
