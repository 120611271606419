// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner(props) {

    console.log(props)
    return (
        <div className="block block-banner">
            <div className="container-fluid">
                <Link to="/" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: props.imgSrc }} />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: props.imgSrcMobile }}
                    />
                </Link>
            </div>
        </div>
    );
}
