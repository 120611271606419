// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    Heart20Svg,
    Cart20Svg,
    FacebookSvg,
    InstagramSvg,
    TwitterSvg,
    YoutubeSvg,
    WhatsappSvg
} from '../../svg';

// application
import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
import DropdownLanguage from './DropdownLanguage';
import theme from '../../data/theme';
import classNames from 'classnames';

function Topbar(props) {
    const { shape, className } = props;

    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });
    const links = [
        { title: <FormattedMessage id="topbar.store" defaultMessage="Store Location" />, url: '/site/store', icon: 'fas fa-map-marker ' },
        { title: <FormattedMessage id="topbar.shipping" defaultMessage="Free Shipping" />, url: '/site/shipping', icon: 'fas fa-truck ' },
    ];

    const items = [
        { type: 'facebook', url: theme.author.facebook, icon: 'fab fa-facebook-f' },
        // { type: 'twitter', url: theme.author.twitter, icon: 'fab fa-twitter' },
        { type: 'instagram', url: theme.author.instagram, icon: 'fab fa-instagram' },
        // { type: 'linkedin-square', url: theme.author.linkedin, icon: 'fab fa-linkedin' },
    ].map((item) => (
        <li key={item.type} className="social-links__item">
            <a
                className={`social-links__link social-links__link--type--${item.type}`}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className={item.icon} />
            </a>
        </li>
    ));

    return (
        <div className="site-header__topbar topbar custom-topbar">

            <div className="topbar__row topbar__row-custom">
                Free Shipping on all orders above PKR 30,000 except Remote Areas.
                <div className="topbar__spring" />
                {/* <ul className="social-links__list">
                    {items}
                </ul> */}

                <div className="topbar__item topbar__item-custom">
                    <a
                        href={theme.author.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FacebookSvg />

                    </a>
                </div>
                <div className="topbar__item topbar__item-custom">
                    <a
                        href={theme.author.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramSvg />

                    </a>
                </div>
                {/* <div className="topbar__item topbar__item-custom">
                    <TwitterSvg />
                </div>
                <div className="topbar__item topbar__item-custom">
                    <YoutubeSvg />
                </div> */}
            </div>

        </div>
    );
}

export default Topbar;
