// react
import React, { useState } from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

import constant from '../../services/constant'

// data stubs
import theme from '../../data/theme';

export default function Footer() {


    const informationLinks = [
        // { title: 'About Us', url: '/site/about-us' },
        // { title: 'Delivery Information', url: '' },
        // { title: 'Privacy Policy', url: '' },
        // { title: 'Brands', url: '' },
        { title: 'Contact Us', url: '/site/contact-us' },
        // { title: 'Returns', url: '' },
        // { title: 'Site Map', url: '' },
    ];

    const accountLinks = [
        // { title: 'Store Location', url: '' },

        { title: 'Order History', url: localStorage.getItem(constant.CONSTANTS.USER_TOKEN) ? "/account/orders": "/account/login"} ,
        { title: 'Wish List', url: '/shop/wishlist' },
        // { title: 'Newsletter', url: '' },
        // { title: 'Specials', url: '' },
        // { title: 'Gift Certificates', url: '' },
        // { title: 'Affiliate', url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
