import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISHLIST_FETCH_ITEMS } from './wishlistActionTypes';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
import { toast } from 'react-toastify';
// import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function addItem(state, product) {
    let token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
    if (token) {
        const itemIndex = state.findIndex((x) => x.id === product.id);
        if (itemIndex === -1) {
            console.log("add wishlist");
            var formData = new FormData();
            formData.append('token', token);
            formData.append('product_id', product.id);
            // formData.append('variant_id', product.id);
            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.ADD_PRODUCT_WISHLIST, data: formData }).then(res => {
                console.log(res)
            });

            return [
                ...state,
                JSON.parse(JSON.stringify(product)),
            ];
        }
    } else {
        window.location = '/account/login';
    }
    return state;
}


function fetchItems(state, wishlist) {

    console.log("tokentokentokentokentokentokentoken")
    console.log(wishlist)

    return [
        ...state,
        wishlist,
    ];
}

function removeItem(state, productId) {
    let token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
    apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.REMOVE_PRODUCT_WISHLIST + token + "&id=" + productId }).then(res => {
        console.log(res)
    });
    return state.filter((x) => x.id !== productId);
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case WISHLIST_ADD_ITEM:
            return addItem(state, action.product);
        case WISHLIST_REMOVE_ITEM:
            return removeItem(state, action.productId);
        case WISHLIST_FETCH_ITEMS:

            console.log(fetchItems(state, action.token))

            return fetchItems(state, action.token)
        default:
            return state;
    }
}


