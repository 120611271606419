import React from 'react';
function Loader(props) {

    return (
        <div className="loading-custom">
            <div className="loading-custom-inner">
                <div className="site-preloader">

                </div>
            </div>
        </div>
    );

}


export default Loader;
