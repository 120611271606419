// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
export default function AccountPageProfile() {
    const user = JSON.parse(localStorage.getItem(constant.CONSTANTS.USER_DATA))
    const userToken = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
    const [firstName, setFirstName] = useState(user ? user.FirstName : "");
    const [lastName, setLastName] = useState(user ? user.LastName : "");
    const [email, setEmail] = useState(user ? user.EmailAddress : "");
    const [mobileNumber, setMobileNumber] = useState(user ? user.MobileNumber : "");
    const [errorFields, setErrorFields] = useState("");


    const handleEditProfile = (e) => {
        e.preventDefault();
        let isValid = handleValidation();

        console.log(errorFields)
        console.log(isValid)

        // return false;

        if (isValid) {

            console.log("Register")
            var formData = new FormData();
            formData.append('token', userToken);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('mobile_number', mobileNumber);
            formData.append('email_address', email);
            console.log(formData)
            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.EDIT_PROFILE, data: formData }).then(res => {
                console.log(res)
                var userData = { ID: user.ID, FirstName: firstName, LastName: lastName, EmailAddress: email, MobileNumber: mobileNumber }
                localStorage.setItem(constant.CONSTANTS.USER_DATA, JSON.stringify(userData))
            });

        }

    }

    const handleValidation = () => {

        // console.log(/\S+@\S+\.\S+/.test(email))

        var isValid = true;
        let errorFields = {}

        if (firstName.length < 1) {
            errorFields.firstName = true
            isValid = false
        }

        if (lastName.length < 1) {
            errorFields.lastName = true
            isValid = false
        }


        if (mobileNumber.length < 1) {
            errorFields.mobileNumber = true
            isValid = false
        }


        // if (!(/\S+@\S+\.\S+/.test(email)) || email.length < 1) {
        //     errorFields.email = true
        //     isValid = false
        // }

        setErrorFields(errorFields)

        return isValid;

    }

    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">First Name</label>
                            <input
                                id="profile-first-name"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            {errorFields.firstName ? <span className="error-field">Please enter first name</span> : null}

                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Last Name</label>
                            <input
                                id="profile-last-name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                            {errorFields.lastName ? <span className="error-field">Please enter last name</span> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-phone">Mobile Number</label>
                            <input
                                id="profile-phone"
                                className="form-control"
                                placeholder="03XX XXXXXXX"
                                type="text"
                                maxLength={11}
                                value={mobileNumber}
                                onChange={e => setMobileNumber(e.target.value)}
                            />
                            {errorFields.mobileNumber ? <span className="error-field">Please enter mobile number</span> : null}
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button onClick={handleEditProfile} type="button" className="btn btn-primary text-color-white">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
