// react
import React, { useEffect, useState } from 'react';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageTerms() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Terms And Conditions', url: '' },
    ];


    const [content, setContent] = useState([]);

    useEffect(() => {
        console.log('mounted');
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_TC }).then(res => {
            console.log(res.data)
            console.log(res.data)
            var arr = []
            arr.push(res.data)
            setContent(arr)
        });
        // }
        return () => console.log('unmounting...');
    }, [])


    return (
        <React.Fragment>
            <Helmet>
                <title>{`Terms And Conditions — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">{content[0]?.Title}</h1>
                        </div>

                        <div className="document__content typography" dangerouslySetInnerHTML={{ __html: content[0]?.Content.replace(/(<? *script)/gi, 'illegalscript') }}>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;
