 // react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'

export default function AccountForgetPassword() {

    const [email, setEmail] = useState("");

    const [errorFields, setErrorFields] = useState("");



    const handleReset = (e) => {
        e.preventDefault();
        let isValid = handleChangeValidations();

        // console.log(errorFields)
        // console.log(isValid)

        // return false
        // console.log("Forget")

        if (isValid) {
            var formData = new FormData();
            formData.append('email_address', email);
            // console.log(formData)

            apiServices.postRequest({ url: constant.API.BASE_URL + constant.API.FORGET_PASSWORD, data: formData }).then(res => {
                // console.log(res)
                // props.history.push('/');
            });
        }
    }

    const handleChangeValidations = () => {

        // console.log(/\S+@\S+\.\S+/.test(email))

        var isValid = true;
        let errorFields = {}


        if (!(/\S+@\S+\.\S+/.test(email)) || email.length < 1) {
            errorFields.email = true;
            isValid = false;
        }

        setErrorFields(errorFields)

        return isValid;

    }


    return (
        <div className="card">
            <Helmet>
                <title>{`Forget Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Reset Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-confirm">Email Address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email-forget"
                                placeholder="Email Address"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {errorFields.email ? <span className="error-field">Please enter valid email address</span> : null}
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary text-color-white" onClick={handleReset}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
