/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
// import qs from 'query-string';
// import { getCategories, getCategoryBySlug } from '../fake-server/endpoints/categories';
// import {
//     getDiscountedProducts,
//     getFeaturedProducts,
//     getLatestProducts,
//     getPopularProducts,
//     getProductBySlug,
//     getProductsList,
//     getRelatedProducts,
//     getSuggestions,
//     getTopRatedProducts,
// } from '../fake-server/endpoints/products';

import constant from '../services/constant'


const shopApi = {
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    getCategories: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // http://beta.marche.live/api/categories?depth=2


        // return fetch(`http://beta.marche.live/api/categories?depth=2`)
        //     .then((response) => response);


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_CATEGORY + "depth=" + options.depth);
        let data = await response.json()
        // console.log("getCategories")
        // console.log(data)
        return data;


        // This is for demonstration purposes only. Remove it and use the code above.
        // return getCategories(options);
    },


    getCategoriesHeaders: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // http://beta.marche.live/api/categories?depth=2


        // return fetch(`http://beta.marche.live/api/categories?depth=2`)
        //     .then((response) => response);


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_CATEGORY + "depth=" + options.depth);
        let data = await response.json();

        let arrTemp = data;
        let arr = [];

        for (let i = 0; i < arrTemp.length; i++) {
            // var obj = {
            //     title: '',
            //     url: '',
            //     submenu: {
            //         type: 'menu',
            //         menu: [
            //             {
            //                 title: 'Soldering Equipment',
            //                 url: '',
            //                 submenu: [],
            //             },
            //             { title: 'Light Bulbs', url: '' },
            //             { title: 'Batteries', url: '' },
            //             { title: 'Light Fixtures', url: '' },
            //             { title: 'Warm Floor', url: '' },
            //             { title: 'Generators', url: '' },
            //             { title: 'UPS', url: '' },
            //         ],
            //     },
            // }




            arr.push({
                title: arrTemp[i].name,
                url: '/shop/catalog/' + arrTemp[i].slug,
                submenu: {}
            })



        }
        for (let j = 0; j < arrTemp.length; j++) {
            if (arrTemp[j].children.length > 0) {
                arr[j].submenu.type = 'menu';
                arr[j].submenu.menu = [];
            }

            if (arr[j].submenu.menu !== undefined) {
                for (let k = 0; k < arrTemp[j].children.length; k++) {
                    // if (arrTemp[k].children.length > 0) {
                    //     arr[k].submenu.type = 'menu';
                    //     arr[k].submenu.menu = [];
                    // }
                    arr[j].submenu.menu.push({
                        title: arrTemp[j].children[k].name,
                        url: '/shop/catalog/' + arrTemp[j].children[k].slug,
                    })

                }
            }

        }


        for (let i = 0; i < arr.length; i++) {

            if (arr[i].submenu.type === undefined) {
                arr[i].submenu = null
            }
        }


        // console.log("getCategoriesHeaders")
        // console.log(arr)

        return arr;


        // This is for demonstration purposes only. Remove it and use the code above.
        // return getCategories(options);
    },


    /**
    * @param {object?} options
    * @param {number?} options.depth
    *
    * @return {Promise<Array<object>>}
    */
    getPopularCategories: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // http://beta.marche.live/api/categories?depth=2


        // return fetch(`http://beta.marche.live/api/categories?depth=2`)
        //     .then((response) => response);


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_CATEGORY + "depth=" + options.depth);
        let data = await response.json()

        console.log("getPopularCategories")



        // for (let i = 0; i < data.length; i++) {

        //     data[i].title = data[i].name
        //     data[i].products = data[i].items
        //     data[i].url = "/" + data[i].slug
        //     data[i].subcategories = data[i].children

        //     for (let j = 0; j < data[i].subcategories.length; j++) {
        //         data[i].subcategories[j].title = data[i].subcategories[j].name
        //         data[i].subcategories[j].products = data[i].subcategories[j].items
        //         data[i].subcategories[j].url = "/" + data[i].subcategories[j].slug
        //     }

        // }

        console.log(data)
        return data;
        // This is for demonstration purposes only. Remove it and use the code above.
        // return getCategories(options);
    },





    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getCategoryBySlug: async (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        var URL = ""

        if (options.depth == undefined) {
            URL = constant.API.BASE_URL + constant.API.GET_CATEGORY_BY_SLUG + slug
        } else {
            URL = constant.API.BASE_URL + constant.API.GET_CATEGORY_BY_SLUG + slug + "?depth=2"
        }


        console.log(URL)
        console.log(options)


        let response = await fetch(URL);
        let data = await response.json()
        console.log("getCategoryBySlug")
        console.log(data)
        if (options.depth == undefined) {
            return data;
        } else {

            if (data[0] != undefined) {
                return data[0].children;
            } else {
                return data;
            }


        }

        // This is for demonstration purposes only. Remove it and use the code above.

        // console.log(getCategoryBySlug(slug, options))

        // return getCategoryBySlug(slug, options);
    },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */
    getProductBySlug: async (slug) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/screwdriver-a2017.json
         *
         * where:
         * - screwdriver-a2017 = slug
         */
        // return fetch(`https://example.com/api/products/${slug}.json`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)
        console.log(slug)
        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_BY_SLUG + slug);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getProductBySlug")
        console.log(data)
        return data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getProductBySlug(slug);
    },
    /**
     * Returns array of related products.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
    getRelatedProducts: async (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)

        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getRelatedProducts")
        // console.log(data)
        return data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getRelatedProducts(slug, options);
    },
    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: async (options = {}, filters = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        // const params = { ...options };
        //
        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        //
        // return fetch(`https://example.com/api/products.json?${qs.stringify(params)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)

        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.dir = options.dir === undefined ? "" : options.dir
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price
        options.query = options.query === undefined ? "" : options.query

        // console.log(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "&dir=" + options.dir + "" + "&query=" + options.query + "&token=" + token)
        // console.log(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "")
        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "&dir=" + options.dir + "" + "&query=" + options.query + "&token=" + token);
        let data = await response.json();
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getProductsList kashif")
        // console.log(data)
        return data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getProductsList(options, filters);
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());


        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&featured=true" + "&token=" + token);

        let data = await response.json()
        console.log("product list page 123")
        console.log(data)
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getFeaturedProducts")
        // console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getFeaturedProducts(options);
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getLatestProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        // This is for demonstration purposes only. Remove it and use the code above.
        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&latest=true" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getLatestProducts")
        // console.log(data)
        return data.data;

        // return getLatestProducts(options);
    },
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getTopRatedProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)

        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&is_top_offer=Yes" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getTopRatedProducts")
        // console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getTopRatedProducts(options);
    },
    /**
     * Returns an array of discounted products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getDiscountedProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&is_best_offer=Yes" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getDiscountedProducts")
        // console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getDiscountedProducts(options);
    },

    getBestSellerProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&is_best_seller=Yes" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        // console.log("getDiscountedProducts")
        // console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getDiscountedProducts(options);
    },



    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price


        console.log(token)
        console.log(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&latest=true" + "&token=" + token)


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "" + "&latest=true" + "&token=" + token);
        let data = await response.json()
        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }
        console.log("getPopularProducts")
        console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getPopularProducts(options);
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: async (query, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;
        const token = localStorage.getItem(constant.CONSTANTS.USER_TOKEN)


        options.page = options.page === undefined ? "" : options.page
        options.limit = options.limit === undefined ? "" : options.limit
        options.sort = options.sort === undefined ? "" : options.sort
        options.category = options.category === undefined ? "" : options.category
        options.price = options.price === undefined ? "" : options.price
        options.query = options.query === undefined ? "" : options.query

        // console.log(query)

        let response = await fetch(constant.API.BASE_URL + constant.API.GET_PRODUCT_LIST + "page=" + options.page + "&limit=" + options.limit + "&sort=" + options.sort + "&filter_category=" + options.category + "&filter_price=" + options.price + "&query=" + options.query + "" + "&latest=true" + "&token=" + token);
        let data = await response.json()

        for (let i = 0; i < data.length; i++) {
            data[i].price = parseFloat(data[i].price);
        }

        // console.log("getSuggestions")
        // console.log(data)
        return data.data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getSuggestions(query, options);
    },

    getBanners: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_BANNERS);
        let data = await response.json()

        // for (let i = 0; i < data.length; i++) {
        //     data[i].price = parseFloat(data[i].price);
        // }

        // console.log("getBanners")
        // console.log(data)
        return data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getSuggestions(query, options);
    },


    getHeaders: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // let response = await fetch(`http://beta.marche.live/api/categories?depth=0`);
        // let data = await response.json()
        // return data;


        let response = await fetch(constant.API.BASE_URL + constant.API.GET_HEADERS);
        let data = await response.json()
        // for (let i = 0; i < data.length; i++) {
        //     data[i].price = parseFloat(data[i].price);
        // }
        return data;

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getSuggestions(query, options);
    },

};

export default shopApi;
