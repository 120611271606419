// react
import React, { useEffect, useState } from 'react';
import apiServices from '../../api/apiservices';
import constant from '../../services/constant'
// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import {
    PlusSvg, MinusSvg
} from '../../svg';

// data stubs
import theme from '../../data/theme';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
function SitePageFaq() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Frequently Asked Questions', url: '' },
    ];


    const [content, setContent] = useState([]);
    const [collapse, setCollapse] = useState(0);

    const toggle = (e) => {
        console.log("toggle")
        let event = e.target.dataset.event;

        console.log(event)

        setCollapse(collapse === Number(event) ? -1 : Number(event));
    }

    useEffect(() => {
        console.log('mounted');
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_FAQ }).then(res => {
            console.log(res.data)
            setContent(res.data)
        });
        return () => console.log('unmounting...');
    }, [])


    const faqList = content.map((item, index) => {
        return (
            <div style={{ marginBottom: '1rem' }} key={index}>
                <div data-event={index} onClick={toggle}>
                    {
                        collapse === index ? <MinusSvg class="collapse-custom" data-event={index} onClick={toggle} /> : <PlusSvg class="collapse-custom" data-event={index} onClick={toggle} />
                    }
                    <h6 className="faq-h6" data-event={index} onClick={toggle}>{item.Title}
                        <div data-event={index} onClick={toggle} className="icon-collapse" >

                        </div>
                    </h6>
                </div>
                {/* <CardHeader onClick={toggle} data-event={index}>{item.Title}</CardHeader> */}
                <Collapse isOpen={collapse === index} key={index} >
                    <div dangerouslySetInnerHTML={{ __html: item.Content }} />
                </Collapse>
            </div>
        )
    })


    return (
        <React.Fragment>
            <Helmet>
                <title>{`FAQ — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Frequently Asked Questions" breadcrumb={breadcrumb} />

            <div className="block faq">
                <div className="container">
                    <div className="faq__section">
                        <div className="faq__section-title">
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        {faqList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageFaq;
