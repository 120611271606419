// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlockHeader from '../shared/BlockHeader';

export default function BlockCategories(props) {
    const { title, layout, categories, loading, gotoPage } = props;


    console.log("block categories")
    console.log(categories)
    console.log(gotoPage)

    const categoriesListCatalog = categories.map((category, index) => {

        return (

            <div key={index} class="category-list-item-custom col-6 col-sm-2">

                <Link to={gotoPage == "catalog" ? '/shop/catalog/' + category.slug : '/shop/category/' + category.slug}>
                    <img src={category.image} alt="" />
                </Link>

                <div class="custom-txt pt-0 pt-sm-2">
                    <Link to={gotoPage == "catalog" ? '/shop/catalog/' + category.slug : '/shop/category/' + category.slug}>{category.name}</Link>

                </div>

            </div>

        );
    });

    console.log("categoriesListCatalog")
    console.log(categoriesListCatalog)


    const categoriesListCategories = categories.map((category, index) => {

        return (
            <div key={index} className='block-categories__item_custom col-6 col-sm-2 mb-3 pl-2 pr-2 pl-sm-0 pr-sm-0'>
                <div className="category-card__image hover-effect">
                    <Link to={gotoPage == "catalog" ? '/shop/catalog/' + category.slug : '/shop/category/' + category.slug}>
                        <img src={category.image} alt="" />
                    </Link>
                </div>
            </div>
        );

    });


    console.log("categoriesListCategories")
    console.log(categoriesListCategories)

    return (
        <div className={`white-color-bg block block--highlighted block-categories block-categories--layout--${layout}`}>
            <div className="container-fluid">
                <BlockHeader title={title} />

                {
                    gotoPage == "catalog" ?
                        <div className="block-catalog__list_custom row">
                            {categoriesListCatalog}
                        </div>
                        :
                        <div className="block-categories__list_custom row no-gutters">
                            {categoriesListCategories}
                        </div>
                }

            </div>
        </div>
    );
}

BlockCategories.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.array,
    layout: PropTypes.oneOf(['classic', 'compact']),
};

BlockCategories.defaultProps = {
    categories: [],
    layout: 'classic',
};
