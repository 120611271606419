// react
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import constant from '../../services/constant';
import apiServices from '../../api/apiservices';

export default function AccountPageDashboard() {
    const address = addresses[0];
    const orders = allOrders.slice(0, 3).map((order) => (
        <tr key={order.id}>
            <td>
                <Link to="/account/orders/5">
                    #
                    {order.id}
                </Link>
            </td>
            <td>{order.date}</td>
            <td>{order.status}</td>
            <td>{order.total}</td>
        </tr>
    ));

    const userDetails = JSON.parse(localStorage.getItem(constant.CONSTANTS.USER_DATA))
    console.log(userDetails)
    const [token, setToken] = useState(localStorage.getItem(constant.CONSTANTS.USER_TOKEN));
    const [userAddress, setUserAddress] = useState([]);

    useEffect(() => {
        console.log('mounted');
        // if (addressId != "-1") {
        apiServices.getRequest({ url: constant.API.BASE_URL + constant.API.GET_CUSTOMER_ADDRESS + token }).then(res => {
          if(res.data !== undefined){

            for (let i = 0; i < res.data.length; i++) {
                console.log(res.data[i])
                if (res.data[i].IsDefault == "Yes") {
                    var arr = []
                    arr.push(res.data[i]);
                    setUserAddress(arr)
                    console.log(res.data[i])
                    break;
                }
            }
          }

        });
        // }
        return () => console.log('unmounting...');
    }, [])

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>

            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src="images/avatars/avatar-3.jpg" alt="" />
                    </div>
                    <div className="profile-card__name">{userDetails.FirstName} {userDetails.LastName}</div>
                    <div className="profile-card__email">{userDetails.EmailAddress}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-primary btn-sm text-color-white">Edit Profile</Link>
                    </div>
                </div>
            </div>

            {
                userAddress[0] == undefined ?
                    // <div className="dashboard__profile card profile-card">
                    <Link to="/account/addresses/-1" className="addresses-list__item addresses-list__item--new dashboard__profile card profile-card">
                        <div className="addresses-list__plus" />
                        <div className="btn btn-primary btn-sm text-color-white">Add Address</div>
                    </Link>
                    // </div>
                    :

                    <div className="dashboard__address card address-card address-card--featured">
                        <div className="address-card__badge text-color-white">Default Address</div>
                        <div className="address-card__body">
                            <div className="address-card__name">{`${userAddress[0]?.Title}`}</div>
                            <div className="address-card__row">
                                {userAddress[0]?.Address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Mobile Number</div>
                                <div className="address-card__row-content">{userAddress[0]?.MobileNumber}</div>
                            </div>
                            <div className="address-card__footer">
                                <Link to={"/account/addresses/" + userAddress[0]?.ID}>Edit Address</Link>
                            </div>
                        </div>
                    </div>
            }



        </div>
    );
}
